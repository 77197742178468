import styled from "styled-components"
import TopLateReceiptsPerUfModel from "./top-late-receipts-per-uf-model"
import TopTableReceiptsPerProject from "./top-late-receipts-per-project"
import Infrastructure from "./infrastructure"
import SelectField from "../../ui/select-field"
import { useProjects } from "../../../hooks/projects"
import { useState } from "react"
import { selectModelOptions, selectUfOptions } from "../../../utils"

const TablesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px
`

const ProjectFieldWrapper = styled.div`
  flex: 4;
  @media (max-width: 800px) {
    flex: unset;
    width: 100%;
  }
`

const SelectFieldWrapper = styled.div`
  flex: 1;
`

const Overview = () => {
  const { data: projects } = useProjects()

  const [ projectId, setProjectId ] = useState<number | null>(null)
  const [ uf, setUf ] = useState<string | null>(null)
  const [ model, setModel ] = useState<string | null>(null)

  const projectOptions = projects ? [
    {
      value: null,
      description: "Todos"
    },
    ...projects
      .map(p => ({ value: String(p.id), description: p.name }))
      .sort((a, b) => a.description.localeCompare(b.description))
  ] : [
    {
      value: null,
      description: "Carregando..."
    }
  ]

  return (
    <TablesWrapper>
      <FormWrapper>
        <ProjectFieldWrapper>
          <SelectField
            label="Projeto"
            options={projectOptions}
            value={projectId ? String(projectId) : null}
            onChange={projectId => setProjectId(projectId ? parseInt(projectId) : null)}
          />
        </ProjectFieldWrapper>
        <SelectFieldWrapper>
          <SelectField
            label="UF"
            options={selectUfOptions}
            onChange={setUf}
            value={uf}
          />
        </SelectFieldWrapper>
        <SelectFieldWrapper>
          <SelectField
            label="Modelo"
            options={selectModelOptions}
            onChange={setModel}
            value={model}
          />
        </SelectFieldWrapper>
      </FormWrapper>
      <TopTableReceiptsPerProject
        projectId={projectId}
        uf={uf}
        model={model}
      />
      <TopLateReceiptsPerUfModel
        projectId={projectId}
        uf={uf}
        model={model}
      />
      <Infrastructure />
    </TablesWrapper>
  )
}

export default Overview