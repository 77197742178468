import styled from "styled-components"
import ExpandNavButton from "./expand-nav-button"
import MenuContent from "./menu-content"
import { useState } from "react"

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 55px;
  background-color: #0B1045;
  display: flex;
  align-items: center;
  z-index: 1000;
  @media (min-width: 1600px) {
    display: none;
  }
`

const Spacer = styled.div`
  flex: 1;
`

const Title = styled.div`
  color: white;
  font-weight: bolder;
  font-size: 22px;
  padding: 0 15px;
`

const TopMenu = () => {
  const [ expanded, setExpanded ] = useState<boolean>(false)
  return (
    <Wrapper>
      <Title>
        Backoffice
      </Title>
      <Spacer />
      <ExpandNavButton onClick={() => setExpanded(!expanded)} active={expanded} />
      <MenuContent expanded={expanded} onSelected={() => setExpanded(false)} />
    </Wrapper>
  )
}

export default TopMenu