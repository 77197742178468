import styled from "styled-components"
import { useLogout } from "../../../../hooks/session"
import React, { ReactNode } from "react"
import { useNavigate } from "react-router-dom"

const Wrapper = styled.div`
  position: fixed;
  top: 55px;
  background-color: #373779;
  width: 100%;
  height: calc(100vh - 55px);
`

const MenuContent = (
  props: {
    expanded: boolean,
    onSelected: () => void
  }
) => {
  const {
    expanded, onSelected
  } = props
  if (!expanded) {
    return null;
  }
  return (
    <Wrapper>
      <MenuItem path="/dashboard" onSelected={onSelected}>
        Home
      </MenuItem>
      <LogoutMenuItem />
    </Wrapper>
  )
}

const MenuItemWrapper = styled.button`
  color: white;
  padding: 15px;
  width: 100%;
  background-color: transparent;
  border: none;
  text-align: left;
  font-size: 16px;
`

const MenuItem = (props: { path: string, children: ReactNode, onSelected: () => void }) => {
  const { path, children, onSelected } = props

  const navigate = useNavigate()

  const handleOnClick = (e: React.MouseEvent) => {
    e.preventDefault()
    navigate(path)
    onSelected()
  }

  return (
    <MenuItemWrapper onClick={handleOnClick}>
      {children}
    </MenuItemWrapper>
  )
}

const LogoutMenuItem = () => {
  const { mutate, isLoading, isError } = useLogout()

  if (isLoading) {
    return (
      <MenuItemWrapper>
        Saindo...
      </MenuItemWrapper>
    )
  }

  if (isError) {
    return (
      <MenuItemWrapper>
        Erro ao Sair!
      </MenuItemWrapper>
    )
  }

  return (
    <MenuItemWrapper onClick={() => mutate()}>
      Sair
    </MenuItemWrapper>
  )
}

export default MenuContent