import styled from "styled-components"
import AccountOptions from "./account-options"
import React, { ReactNode } from "react"
import { useLocation, useNavigate } from "react-router-dom"

const Wrapper = styled.div`
  height: 100vh;
  width: 250px;
  left: 0;
  background-color: #0B1045;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: fixed;
  @media (max-width: 1600px) {
    display: none;
  }
`

const Logo = styled.div`
  margin-top: 15px;
  font-size: 28px;
  padding: 0 15px;
  font-weight: bolder;
  color: white;
`

const Menu = styled.div`
  color: white;
  font-size: 15px;
  padding-top: 15px;
`

const Spacer = styled.div`
  flex: 1;
`

const SideMenu = () => {
  return (
    <Wrapper>
      <Logo>
        Backoffice
      </Logo>
      <Menu>
        <MenuItem path="/dashboard">
          Home
        </MenuItem>
      </Menu>
      <Spacer />
      <AccountOptions />
    </Wrapper>
  )
}

const MenuItemWrapper = styled.div<{ active: boolean }>`
  font-size: 16px;
  padding: 7px 15px;
  cursor: pointer;
  &:hover {
    background-color: #080726;
  }
  ${props => props.active && `background-color: #3a3977 !important;`}
`

const MenuItem = (
  props: {
    children: ReactNode;
    path: string;
  }
) => {
  const location = useLocation()
  const navigate = useNavigate()

  const handleOnClick = (e: React.MouseEvent) => {
    e.preventDefault()
    navigate(props.path)
  }

  return (
    <MenuItemWrapper onClick={handleOnClick} active={location.pathname.startsWith(props.path)}>
      {props.children}
    </MenuItemWrapper>
  )
}

export default SideMenu