import { ReactNode } from "react"
import styled from "styled-components"

type Props = {
  page?: number;
  onChange?: (page: number | string) => void;
  total?: number;
}

const Page = styled.div<{ active: boolean }>`
  background-color: ${props => {
    if (props.active) {
      return "#0F1B8D"
    }
    return "#4550B7"
  }};
  color: white;
  font-size: 14px;
  min-width: 25px;
  padding: 0 4px;
  line-height: 25px;
  text-align: center;
  user-select: none;
  cursor: pointer;
  box-sizing: border-box;
  &:hover {
    background-color: #0F1B8D !important;
  }
`

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  &:hover ${Page} {
    background-color: #4550B7;
  }
`

const Pagination = (props: Props) => {
  const {
    page = 1, onChange, total = 1
  } = props

  if (total <= 1) {
    return null
  }

  const pages: ReactNode[] = []

  pages.push(
    <Page key={1} active={1 === page} onClick={() => onChange?.(1)}>
      1
    </Page>
  )

  const startPage = Math.max(2, page - 2);
  const endPage = Math.min(total - 1, page + 2);

  if (startPage > 2) {
    pages.push(<span key="start-ellipsis">...</span>);
  }

  for (let i = startPage; i <= endPage; i++) {
    pages.push(
      <Page key={i} active={i === page} onClick={() => onChange?.(i)}>
        {i}
      </Page>
    );
  }

  if (endPage < total - 1) {
    pages.push(<span key="end-ellipsis">...</span>);
  }

  pages.push(
    <Page key={total} active={total === page} onClick={() => onChange?.(total)}>
      {total}
    </Page>
  );

  return <Wrapper>{pages}</Wrapper>;
}

export default Pagination