import withRedirectUnauthenticated from "../../../hocs/with-redirect-unauthenticated"
import Column from "../../ui/column"
import Layout from "../layout/layout"

const ProductsPage = () => {
  return (
    <Layout>
      <Column padding="15px" gap="15px">
        <h1>Em construção</h1>
      </Column>
    </Layout>
  )
}

export default withRedirectUnauthenticated(ProductsPage)