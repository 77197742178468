import { BrowserRouter, Navigate, Routes as ReactRouterRoutes, Route } from 'react-router-dom'

import LoginPage from './components/pages/login/login-page'
import AppContextProvider from './context/app-context-provider'
import { QueryClient, QueryClientProvider } from 'react-query'
import Dashboard from './components/pages/dashboard/dashboard-page'
import Overview from './components/pages/dashboard/overview'
import ClientsPage from './components/pages/clients/clients-page'
import ClientsList from './components/pages/clients/clients-list'
import CustomerRegister from './components/pages/clients/customer-register'
import UsersPage from './components/pages/users/users-page'
import ProjectsPage from './components/pages/projects/projects-page'
import CompaniesPage from './components/pages/companies/companies-page'
import ProductsPage from './components/pages/products/products-page'
import CostsPage from './components/pages/costs/costs-page'
import QueueStatus from "./components/pages/dashboard/queue-status"
import ExtractorPerformance from "./components/pages/dashboard/extractor-performance"
import ImportPerformance from "./components/pages/dashboard/import-performance"
import QueueSearch from "./components/pages/dashboard/queue-search"
import AttemptSearch from "./components/pages/dashboard/attempt-search"
import AttemptStatus from './components/pages/dashboard/attempt-status'

const queryClient = new QueryClient()

const Routes = (
  <>
    <Route path="/" element={<Navigate to="/dashboard" />} />
    <Route path="dashboard" element={<Dashboard />}>
      <Route index element={<Overview />} />
      <Route path="queue-status" element={<QueueStatus />} />
      <Route path="attempt-status" element={<AttemptStatus />} />
      <Route path="extractor-performance" element={<ExtractorPerformance />} />
      <Route path="import-performance" element={<ImportPerformance />} />
      <Route path="queue-search" element={<QueueSearch />} />
      <Route path="attempt-search" element={<AttemptSearch />} />
    </Route>
    <Route path="/clients" element={<ClientsPage />}>
      <Route index element={<Navigate to="clientsList" replace/>} />
      <Route path="clientsList" element={<ClientsList />} />
      <Route path="customerRegister" element={<CustomerRegister />} />
    </Route>
    <Route path="/users" element={<UsersPage />} />
    <Route path="/projects" element={<ProjectsPage />} />
    <Route path="/companies" element={<CompaniesPage />} />
    <Route path="/products" element={<ProductsPage />} />
    <Route path="/costs" element={<CostsPage />} />
    <Route path="/login" element={<LoginPage />} />
  </>
)

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AppContextProvider>
          <ReactRouterRoutes>
            {Routes}
          </ReactRouterRoutes>
        </AppContextProvider>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App