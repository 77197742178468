import { useReceiptQueue } from "../../../hooks/receipts"
import Table from "../../ui/table"
import useUrlState from "../../../hooks/use-url-state"
import { useProjects } from "../../../hooks/projects"
import SelectField from "../../ui/select-field"
import { getErrorMessage, selectModelOptions, selectUfOptions } from "../../../utils"
import styled from "styled-components"
import TextField from "../../ui/text-field"
import { useEffect, useRef, useState } from "react"
import Pagination from "../../ui/pagination"
import { useNavigate } from "react-router-dom"
import Modal from "../../ui/modal"

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  overflow-x: auto;
`

const AccessKeyWrapper = styled.div`
  font-family: monospace;
`

const AccessKeyFieldWrapper = styled.div`
  min-width: 300px;
`

const SelectFieldWrapper = styled.div<{ flex?: number }>`
  flex: ${props => props.flex || 1};
`

const DateInputWrapper = styled.div`
  width: 193px;
  display: flex;
  flex-direction: column;
`

const DateInput = styled.input`
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  margin-top: 2px;
  border: solid 1px black;
`

const DateLabel = styled.label`
  font-weight: bolder;
  font-size: 14px;
`

const Button = styled.button`
  height: 43px;
  align-self: flex-end;
  background-color: white;
  border: solid 1px black;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bolder;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
`

const ResponsiveTable = styled.div`
  width: 100%;
  overflow-x: auto;
`

const QueueSearch = () => {

  const { data: projects, isLoading, error } = useProjects()

  const accessKeyTimeoutHandle = useRef<any>()

  const navigate = useNavigate()

  const [accessKey, setAccessKey] = useUrlState("accessKey", null)
  const [projectId, setProjectId] = useUrlState("projectId", null)
  const [inputProjectId, setInputProjectId] = useState<string | null>()
  const [uf, setUf] = useUrlState("uf", null)
  const [inputUf, setInputUf] = useState<string | null>()
  const [model, setModel] = useUrlState("model", null)
  const [inputModel, setInputModel] = useState<string | null>()
  const [status, setStatus] = useUrlState("status", null)
  const [inputStatus, setInputStatus] = useState<string | null>()
  const [page, setPage] = useUrlState("activePage", null);
  const [currentPage, setCurrentPage] = useState<string | null>()
  const [startDate, setStartDate] = useUrlState("startDate", null);
  const [endDate, setEndDate] = useUrlState("endDate", null);
  const [formattedStartDate, setFormattedStartDate] = useState<string>();
  const [formattedEndDate, setFormattedEndDate] = useState<string>();

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [dateErrorMessage, setDateErrorMessage] = useState<string>()

  const [formAccessKey, setFormAccessKey] = useState<string | null>(accessKey)

  const projectOptions = projects ? [
    {
      value: null,
      description: "Todos"
    },
    ...projects
      .map(p => ({ value: String(p.id), description: p.name }))
      .sort((a, b) => a.description.localeCompare(b.description))
  ] : [
    {
      value: null,
      description: "Carregando..."
    }
  ]

  const onAttemptsClick = (accessKey: string, projectId: number) => {
    const path = '/dashboard/attempt-search?accessKey=' + accessKey + '&projectId=' + projectId
    navigate(path)
  }

  const dataFilter = () => {
    if (startDate && endDate) {
      const currentDate = new Date(formatDate(String(new Date())).split(' ')[0])
      const initialDate = new Date(startDate)
      const finalDate = new Date(endDate)

      if (initialDate > currentDate || finalDate > currentDate) {
        setDateErrorMessage("Erro no filtro de datas!\nAs datas não podem estar no futuro.")
        setOpenModal(true)
      } else if(initialDate > finalDate) {
        setDateErrorMessage("Erro no filtro de datas!\nA data inicial não pode ser maior do que a data final.")
        setOpenModal(true)
      } else if (initialDate <= finalDate) {
        setFormattedStartDate(startDate)
        setFormattedEndDate(endDate)
      }
    }

    setAccessKey(formAccessKey)
    inputProjectId ? setProjectId(inputProjectId) : setProjectId(null)
    inputUf ? setUf(inputUf) : setUf(null)
    inputStatus ? setStatus(inputStatus) : setStatus(null)
    inputModel ? setModel(inputModel) : setModel(null)
  }

  const {
    data: queue
  } = useReceiptQueue({
    projectId: projectId ? parseInt(projectId) : null, uf, model, accessKey, status, currentPage, formattedStartDate, formattedEndDate
  })

  useEffect(() => {

    clearTimeout(accessKeyTimeoutHandle.current)

    accessKeyTimeoutHandle.current = setTimeout(() => setFormAccessKey(formAccessKey), 1500)

    return () => clearTimeout(accessKeyTimeoutHandle.current)

  }, [formAccessKey, accessKeyTimeoutHandle])

  useEffect(() => {
    setCurrentPage(page)
  }, [page])

  return (
    <>
      <Modal open={openModal} type="ALERT" onClose={() => setOpenModal(false)}><strong style={{ whiteSpace: 'pre-line', textAlign: "center" }}>{dateErrorMessage}</strong></Modal>
      <FormWrapper>
        <AccessKeyFieldWrapper>
          <TextField
            label="Chave de Acesso"
            placeholder="Chave de Acesso"
            value={formAccessKey}
            onChange={setFormAccessKey}
          />
        </AccessKeyFieldWrapper>
        <SelectFieldWrapper>
          <SelectField
            label="Projeto"
            options={projectOptions}
            value={inputProjectId ? String(inputProjectId) : null}
            onChange={setInputProjectId}
          />
        </SelectFieldWrapper>
        <SelectFieldWrapper>
          <SelectField
            label="Status"
            options={[
              { value: null, description: "Todos" },
              { value: "RECEIVED", description: "RECEIVED" },
              { value: "OK", description: "OK" },
              { value: "IDENTIFYING", description: "IDENTIFYING" },
              { value: "GIVE_UP", description: "GIVE_UP" },
              { value: "INVALID", description: "INVALID" },
            ]}
            value={inputStatus}
            onChange={setInputStatus}
          />
        </SelectFieldWrapper>
        <SelectFieldWrapper>
          <SelectField
            label="UF"
            options={selectUfOptions}
            onChange={setInputUf}
            value={inputUf}
          />
        </SelectFieldWrapper>
        <SelectFieldWrapper>
          <SelectField
            label="Modelo"
            options={selectModelOptions}
            onChange={setInputModel}
            value={inputModel}
          />
        </SelectFieldWrapper>
      </FormWrapper>
      <FormWrapper>
        <DateInputWrapper>
          <DateLabel htmlFor="Data Inicial">Data Inicial</DateLabel>
          <DateInput
            type="date"
            name="Data Inicial"
            value={startDate || ""}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </DateInputWrapper>
        <DateInputWrapper>
          <DateLabel htmlFor="Data Final">Data Final</DateLabel>
          <DateInput
            type="date"
            name="Data Final"
            value={endDate || ""}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </DateInputWrapper>
        <Button style={{width: "150px"}} onClick={() => dataFilter()}>Filtrar</Button>
      </FormWrapper>
      {
        isLoading ? "Carregando..." : null
      }
      {
        error ? getErrorMessage(error) : null
      }
      {
        !queue ? null : (
          <>
            <ResponsiveTable>
              <Table mainColumn={2}>
                <thead>
                  <tr>
                    <th>Projeto</th>
                    <th>Chave de Acesso</th>
                    <th>Status</th>
                    <th>Recebimento</th>
                    <th>Processamento</th>
                    <th>Tentativas</th>
                    <th>Ultima Tentativa</th>
                    <th>Opções</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    queue.result.map(item => (
                      <tr key={`${item.projectId}:${item.accessKey}`}>
                        <td>{item.projectName} #{item.projectId}</td>
                        <td>
                          <AccessKeyWrapper>
                            {item.accessKey}
                          </AccessKeyWrapper>
                        </td>
                        <td>{item.status.replaceAll("_", " ")}</td>
                        <td>{formatDate(item.receivedAt)}</td>
                        <td>{formatDate(item.processedAt)}</td>
                        <td>{item.extractionAttemptAmount}</td>
                        <td>{formatLastAttempt(item.lastExtractionResult)}</td>
                        <td>
                          <Button onClick={() => onAttemptsClick(item.accessKey, item.projectId)}>
                            Tentativas
                          </Button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </ResponsiveTable>
            <Pagination total={queue.total} page={Number(currentPage)} onChange={(newPage) => setPage(String(newPage))} />
          </>
        )
      }
    </>
  )
}

function formatDate(dateString?: string): string {
  if (!dateString) {
    return "Não processada"
  }

  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function formatLastAttempt(result?: string | null) {
  if (!result) return "NO ATTEMPTS"
  return result.replaceAll("_", " ")
}

export default QueueSearch