import { useCallback } from "react"
import useRequest from "./use-request"
import { useQuery } from "react-query"
import { User } from "../types"

export const useCurrentUser = () => {
  const request = useRequest()

  const fetchUser = useCallback(() => request<User>({
    url: "https://api.argobr.com/v1/user/current"
  }).then(response => response.body), [ request ])

  return useQuery("CURRENT_USER", fetchUser)
}