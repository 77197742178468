import { useImportPerformanceReport } from "../../../hooks/report"
import Table from "../../ui/table"
import { useProjects } from "../../../hooks/projects"
import styled from "styled-components"
import SelectField from "../../ui/select-field"
import { selectModelOptions, selectUfOptions } from "../../../utils"
import useUrlState from "../../../hooks/use-url-state"

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px
`

const ProjectFieldWrapper = styled.div`
  flex: 4;
  @media (max-width: 800px) {
    flex: unset;
    width: 100%;
  }
`

const SelectFieldWrapper = styled.div<{ flex?: number }>`
  flex: ${props => props.flex || 1};
`

const ResponsiveTable = styled.div`
  width: 100%;
  overflow-x: auto;
`

const ImportPerformance = () => {

  const { data: projects } = useProjects()

  const [ projectId, setProjectId ] = useUrlState("projectId", null)
  const [ uf, setUf ] = useUrlState("uf", null)
  const [ model, setModel ] = useUrlState("model", null)

  const projectOptions = projects ? [
    {
      value: null,
      description: "Todos"
    },
    ...projects
      .map(p => ({ value: String(p.id), description: p.name }))
      .sort((a, b) => a.description.localeCompare(b.description))
  ] : [
    {
      value: null,
      description: "Carregando..."
    }
  ]

  const { data } = useImportPerformanceReport({
    projectId: projectId ? parseInt(projectId) : null, uf, model
  })

  return (
    <>
      <FormWrapper>
        <ProjectFieldWrapper>
          <SelectField
            label="Projeto"
            options={projectOptions}
            value={projectId ? String(projectId) : null}
            onChange={projectId => setProjectId(projectId)}
          />
        </ProjectFieldWrapper>
        <SelectFieldWrapper>
          <SelectField
            label="UF"
            options={selectUfOptions}
            onChange={setUf}
            value={uf}
          />
        </SelectFieldWrapper>
        <SelectFieldWrapper>
          <SelectField
            label="Modelo"
            options={selectModelOptions}
            onChange={setModel}
            value={model}
          />
        </SelectFieldWrapper>
      </FormWrapper>
      {
        !data ? null : (
          <ResponsiveTable>
            <Table mainColumn={1}>
              <thead>
              <tr>
                <th>Período</th>
                <th>Agendadas</th>
                <th>Em Fila</th>
                <th>Não Encontradas</th>
                <th>Identificando</th>
                <th>Desistências</th>
                <th>Processadas</th>
                <th>Tempo Médio (m)</th>
                <th>Total</th>
              </tr>
              </thead>
              <tbody>
              {
                data.map(item => (
                  <tr style={{ fontWeight: isToday(item.period) ? "bolder" : "normal" }}>
                    <td>
                      { item.period.replace(/(\d{4})-(\d{2})-(\d{2})/, "$1/$2/$3") }
                    </td>
                    <td>
                      { item.scheduled } ({ Math.round(item.scheduled / item.total * 100) }%)
                    </td>
                    <td>
                      { item.received } ({ Math.round(item.received / item.total * 100) }%)
                    </td>
                    <td>
                      { item.notFound } ({ Math.round(item.notFound / item.total * 100) }%)
                    </td>
                    <td>
                      { item.identifying } ({ Math.round(item.identifying / item.total * 100) }%)
                    </td>
                    <td>
                      { item.giveUp } ({ Math.round(item.giveUp / item.total * 100) }%)
                    </td>
                    <td>
                      { item.processed } ({ Math.round(item.processed / item.total * 100) }%)
                    </td>
                    <td>
                      { item.delayMinutes || '--' }
                    </td>
                    <td>
                      { item.total }
                    </td>
                  </tr>
                ))
              }
              </tbody>
            </Table>
          </ResponsiveTable>
        )
      }
    </>
  )
}

function isToday(dateString: string | null | undefined): boolean {
  if (!dateString) {
    return false
  }

  const today = new Date()
  const inputDate = new Date(dateString + " 00:00:00")

  console.log(today, inputDate, dateString)

  return (
    today.getFullYear() === inputDate.getFullYear() &&
    today.getMonth() === inputDate.getMonth() &&
    today.getDate() === inputDate.getDate()
  )
}

export default ImportPerformance