import Table from "../../ui/table"
import styled from "styled-components"
import { useTopLateReceiptsPerProject } from "../../../hooks/report"
import { getErrorMessage } from "../../../utils"
import { useNavigate } from "react-router-dom"

const DashboardTableWrapper = styled.div`
  min-width: 320px;
  max-height: 420px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid #808080;
  border-radius: 5px;

  ::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}
`

const DashboardTableTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #808080;
  margin-bottom: 10px;
`

const DashboardTableTitle = styled.h2`
  font-size: 15px;
  font-weight: 700;
  line-height: 121.83%;
  margin: 0;
`

const DashboardTableSubtitle = styled.p`
  font-size: 13px;
  line-height: 94%;
  margin-bottom: 10px;
`

const TableWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
`

const StyledTable = styled(Table)`
  th {
    position: sticky;
    top: 0;
  }
  tr:hover td {
    background-color: lightgray;
    cursor: pointer;
  }
`

const TopTableReceiptsPerProject = (
  props: {
    uf?: string | null,
    model?: string | null,
    projectId?: number | null
  }
) => {
  const { data, error } = useTopLateReceiptsPerProject(props)

  const navigate = useNavigate()

  const onLineClick = (projectId: number) => {
    const params = new URLSearchParams()

    params.set("projectId", String(projectId))
    params.set("status", "RECEIVED")

    const path = '/dashboard/queue-search?' + params

    navigate(path)
  }

  return (
    <DashboardTableWrapper>
      <DashboardTableTitleWrapper>
        <DashboardTableTitle>
          Notas na Fila
        </DashboardTableTitle>
        <DashboardTableSubtitle>
          por projeto
        </DashboardTableSubtitle>
      </DashboardTableTitleWrapper>
      {
        !error ? (
          <TableWrapper>
            <StyledTable mainColumn={1}>
              <thead>
                <tr>
                  <th>Projeto</th>
                  <th>Qtd</th>
                  <th>Atraso Médio</th>
                </tr>
              </thead>
              <tbody>
                {
                  data ? (
                    data.length ? data.map(item => {
                      return (
                        <tr key={item.projectId} onClick={() => onLineClick(item.projectId)}>
                          <td>{item.projectName}</td>
                          <td>{Math.round(item.amount)}</td>
                          <td>{item.averageLateness > 1 ?
                            `${item.averageLateness} dias` :
                            `${item.averageLateness} dia`}</td>
                        </tr>
                      )
                    }) : (
                      <tr>
                        <td colSpan={3}>Não há notas na fila</td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td colSpan={3} style={ {textAlign: "left"} }>
                        Carregando...
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </StyledTable>
          </TableWrapper>
        ) : (
          <div>
            {getErrorMessage(error)}
          </div>
        )
      }
    </DashboardTableWrapper >
  )
}

export default TopTableReceiptsPerProject