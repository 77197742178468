import styled from "styled-components"
import { useState } from "react"
import AttemptTableData from "./attempt-table-data"

const AccessKeyWrapper = styled.div`
  font-family: monospace;
`
const Button = styled.button`
  background-color: white;
  border: solid 1px black;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bolder;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`

type Props = {
  projectId: number;
  accessKeyCode: string;
  projectName: string;
  workerName: string;
  attemptDt: string;
  result: string;
  id: number;
  hasJson: boolean;
  hasHtml: boolean;
  hasStacktrace: boolean;
  hasScreenshot: boolean;
}

const AttemptTableRow = (props: Props) => {
  const [loadingData, setLoadingData] = useState<boolean>(false)
  
  const hasJson = props.hasJson
  const hasHtml = props.hasHtml
  const hasStacktrace = props.hasStacktrace
  const hasScreenshot = props.hasScreenshot

  const [ selectedData, setSelectedData ] = useState<string>("")

  return (
    <>
      <tr key={`${props.projectId}:${props.accessKeyCode}`}>
        <td>{props.projectName} #{props.projectId}</td>
        <td>
          <AccessKeyWrapper>
            {props.accessKeyCode}
          </AccessKeyWrapper>
        </td>
        <td>{props.workerName}</td>
        <td>{props.result.replaceAll("_", " ")}</td>
        <td>{props.attemptDt}</td>
        <td>
          {
            <>
              {
                !loadingData ?
                  <ButtonsWrapper>
                    {hasJson ? <Button onClick={() => { setLoadingData(true); setSelectedData("json") }}>Json</Button> : null}
                    {hasHtml ? <Button onClick={() => { setLoadingData(true); setSelectedData("html") }}>Html</Button> : null}
                    {hasStacktrace ? <Button onClick={() => { setLoadingData(true); setSelectedData("stacktrace") }}>Log</Button> : null}
                    {hasScreenshot ? <Button onClick={() => { setLoadingData(true); setSelectedData("screenshot") }}>Screenshot</Button> : null}
                  </ButtonsWrapper>
                :
                  <AttemptTableData
                    id={props.id}
                    selectedData={selectedData}
                  />
              }
            </>
          }
        </td>
      </tr>
    </>
  )
}

export default AttemptTableRow