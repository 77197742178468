import { useCallback, useEffect, useState } from "react"
import useRequest from "./use-request"
import { useQuery } from "react-query"

export type InfrastructureItem = {
  description: string,
  lastStatus: string,
  lastReportDt: string,
  nextReportDt: string
}

export const useInfrastructure = () => {

  const [ data, setData ] = useState<{ services: InfrastructureItem[], now: Date }>()
  const [ error, setError ] = useState<any>()
  const request = useRequest()

  const fetchData = useCallback(async () => {
    try {
      const response = await request<{ result: InfrastructureItem[] }>({
        url: "/v1/report/service-status"
      })
      setData({ services: response.body.result, now: new Date() })
    } catch (e) {
      setError(e)
    }
  }, [ request ])

  useEffect(() => { fetchData() }, [fetchData])

  return {
    data, error
  }
}

export type ExtractorStatusItem = {
  uf: string,
  model: string,
  isSleeping: boolean,
  successAmount: number,
  total: number,
  averageTimeMs: number
}

export const useExtractorStatus = (dateTime: string) => {
  const [ data, setData ] = useState<ExtractorStatusItem[]>()
  const [ error, setError ] = useState<any>()
  const request = useRequest()

  const fetchData = useCallback(async () => {
    try {
      const response = await request<{ result: ExtractorStatusItem[] }>({
        url: "/v1/report/extractors-status?startDt=" + dateTime
      })
      setData(response.body.result)
    } catch (e) {
      setError(e)
    }
  }, [request, dateTime])

  useEffect(() => { fetchData() }, [fetchData])

  const disabledExtractorsCount = () => {
    let amount = 0
    data?.forEach(item => {
      if (item.isSleeping) {
        amount++
      }
    })
    return amount
  }
  const disabledExtractorsAmount = disabledExtractorsCount()

  return {
    data, error, disabledExtractorsAmount
  }
}

export type TopLateReceiptsPerProjectItem = {
  projectId: number,
  projectName: string,
  amount: number,
  averageLateness: number
}

export const useTopLateReceiptsPerProject = (
  params: {
    uf?: string | null,
    model?: string | null,
    projectId?: number | null
  }
) => {
  const { uf, model, projectId } = params
  const [ data, setData ] = useState<TopLateReceiptsPerProjectItem[]>()
  const [ error, setError ] = useState<any>()
  const request = useRequest()

  const fetchData = useCallback(async () => {
    try {
      const response = await request<{ result: TopLateReceiptsPerProjectItem[] }>({
        url: "/v1/report/top-late-receipts-per-project",
        params: { uf, model, projectId }
      })
      setData(response.body.result)
    } catch (e) {
      setError(e)
    }
  }, [ request, uf, model, projectId ])

  useEffect(() => { fetchData() }, [ fetchData ])

  return {
    data, error
  }
}

export type TopLateReceiptsPerUfModelItem = {
  uf: string,
  model: string,
  amount: number,
  averageLateness: number
}

export const useTopLateReceiptsPerUfModel = (
  params: {
    uf?: string | null,
    model?: string | null,
    projectId?: number | null
  }
) => {
  const { uf, model, projectId } = params
  const [ data, setData ] = useState<TopLateReceiptsPerUfModelItem[]>()
  const [ error, setError ] = useState<any>()
  const request = useRequest()

  const fetchData = useCallback(async () => {
    try {
      const response = await request<{ result: TopLateReceiptsPerUfModelItem[] }>({
        url: "/v1/report/top-late-receipts-per-uf-model",
        params: { uf, model, projectId }
      })
      setData(response.body.result)
    } catch (e) {
      setError(e)
    }
  }, [ request, uf, model, projectId ])

  useEffect(() => { fetchData() }, [ fetchData ])

  return {
    data, error
  }
}

export type QueueStatusItem = {
  uf: string;
  model: string;
  lateness: number;
  lastExtractionResult: string;
  amount: number;
  avgExtractions: number;
  avgExtractionTimeMs: number;
  processed: number;
  total: number;
}

export const useQueueStatus = (
  params: {
    uf?: string | null,
    model?: string | null,
    projectId?: number | null
  }
) => {

  const { uf, model, projectId } = params

  const [ data, setData ] = useState<QueueStatusItem[]>()
  const [ error, setError ] = useState<any>()
  const request = useRequest()

  const fetchData = useCallback(async () => {
    try {
      const response = await request<{ result: QueueStatusItem[] }>({
        url: "/v1/report/queue-status",
        params: { uf, model, projectId }
      })
      setData(response.body.result)
    } catch (e) {
      setError(e)
    }
  }, [ request, uf, model, projectId ])

  useEffect(() => { fetchData() }, [ fetchData ])

  return {
    data, error
  }

}

export type AttemptStatusItem = {
  uf: string;
  model: string;
  lateness: number;
  lastExtractionResult: string;
  amount: number;
  avgExtractions: number;
  avgExtractionTimeMs: number;
  processed: number;
  total: number;
} 

export const useAttemptStatus = (
  params: {
    uf?: string | null,
    model?: string | null,
    startDate?: string | null;
    endDate?: string | null;
  }
) => {
  
  const { uf, model, startDate, endDate } = params

  const [ data, setData ] = useState<AttemptStatusItem[]>()
  const [ error, setError ] = useState<any>()
  const request = useRequest()

  const fetchData = useCallback(async () => {
    try {
      const response = await request<{ result: AttemptStatusItem[] }>({
        url: "/v1/report/attempt-status",
        params: { uf, model, startDate, endDate }
      })
      setData(response.body.result)
    } catch (e) {
      setError(e)
    }
  }, [ request, uf, model, startDate, endDate ])

  useEffect(() => { fetchData() }, [ fetchData ])

  return {
    data, error
  }

}

export type ExtractorPerformanceReportItem = {
  hour: string;
  result: string;
  avgDurationS: number;
  total: number;
  percent: number;
}

export const useExtractorPerformanceReport = (
  params: {
    uf?: string | null,
    model?: string | null,
    projectId?: number | null
  }
) => {

  const { uf, model, projectId } = params

  const [ data, setData ] = useState<ExtractorPerformanceReportItem[]>()
  const [ error, setError ] = useState<any>()
  const request = useRequest()

  const fetchData = useCallback(async () => {
    try {
      const response = await request<{ result: ExtractorPerformanceReportItem[] }>({
        url: "/v1/report/extractor-performance",
        params: { uf, model, projectId }
      })
      setData(response.body.result)
    } catch (e) {
      setError(e)
    }
  }, [ request, uf, model, projectId ])

  useEffect(() => { fetchData() }, [ fetchData ])

  return {
    data, error
  }

}

export const IMPORT_PERFORMANCE_REPORT_KEY = "IMPORT_PERFORMANCE_REPORT"

export type ImportRerformanceReportItem = {
  period: string;
  scheduled: number;
  received: number;
  notFound: number;
  identifying: number;
  giveUp: number;
  processed: number;
  delayMinutes: number;
  total: number;
}

export const useImportPerformanceReport = (
  params: {
    uf?: string | null,
    model?: string | null,
    projectId?: number | null
  }
) => {

  const { uf, model, projectId } = params

  const request = useRequest()

  const fetchPeriodFilterOptions = useCallback(async () => {
    const response = await request<{ result: ImportRerformanceReportItem[] }>({
      url: "/v1/report/import-performance",
      params: { uf, model, projectId }
    })
    return response.body.result
  }, [ request, uf, model, projectId ])

  return useQuery([ IMPORT_PERFORMANCE_REPORT_KEY, uf, model, projectId ], fetchPeriodFilterOptions)

}