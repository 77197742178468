import withRedirectUnauthenticated from "../../../hocs/with-redirect-unauthenticated"
import Layout from "../layout/layout"
import Column from "../../ui/column"
import Title from "../../ui/title"
import Text from "../../ui/text"
import styled from "styled-components"
import { Outlet, useNavigate } from "react-router"
import Button from "../../ui/button"

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 15px;
`

const ClientsPage = () => {
  const navigate = useNavigate()

  const handleNavigationContent = (route: string) => {
    navigate(`/clients/${route}`)
  }

  return (
    <Layout>
      <Column padding="15px" gap="15px">
        <Column>
          <Title>
            Clientes
          </Title>
          <Text>
            Clientes Argo
          </Text>
        </Column>
        <ButtonsWrapper>
          <Button layout="secondary" onClick={() => handleNavigationContent("clientsList")}>
            Lista de Clientes
          </Button>
          <Button layout="secondary" onClick={() => handleNavigationContent("customerRegister")}>
            Cadastrar Cliente
          </Button>
        </ButtonsWrapper>
        <Outlet />
      </Column>
    </Layout>
  )
}

export default withRedirectUnauthenticated(ClientsPage)