import { ReactNode, useCallback, useState } from "react"
import AppContext from "./app-context"
import { useNavigate } from "react-router"
import { setAuthToken } from "../utils"
import { RejectedHttpResponseError } from "../hooks/use-request"
import Modal from "../components/ui/modal"
import useLocalStorage from "../hooks/use-local-storage"

type State = {
  modalMessage: string | null;
  projectId: number | null;
}

const initialState: State = {
  projectId: null,
  modalMessage: null
}

const AppContextProvider = (props: { children?: ReactNode }) => {
  const [ state, setState ] = useState(initialState)
  const [ projectId, setProjectId ] = useLocalStorage<number>("PROJECT_ID")
  const navigate = useNavigate()

  const {
    modalMessage
  } = state

  const onAuthError = useCallback(() => {
    setAuthToken(null)
    navigate("/login")
  }, [ navigate ])

  const onErrorAlert = useCallback((e: any) => {
    if (e instanceof RejectedHttpResponseError) {
      setState(prev => ({ ...prev, modalMessage: e.message }))
    } else {
      setState(prev => ({ ...prev, modalMessage: "Um erro ocorreu" }))
    }
  }, [ setState ])

  const onProjectSelected = useCallback((projectId: number) => {
    setProjectId(projectId)
  }, [ setProjectId ])

  const onModalClose = () => setState(prev => ({ ...prev, modalMessage: null }))

  return (
    <AppContext.Provider value={{ projectId, onProjectSelected, onAuthError, onErrorAlert }}>
      <Modal open={modalMessage !== null} onClose={onModalClose}>
        {modalMessage}
      </Modal>
      {props.children}
    </AppContext.Provider>
  )
}

export default AppContextProvider