import styled from "styled-components"

const Wrapper = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  width: 30px;
  gap: 5px;
  justify-content: center;
  height: 55px;
  padding: 0 15px;
  background-color: ${props => props.active ? "#373779" : "transparent"};
  cursor: pointer;
  &:hover {
    background-color: #373779;
  }
`

const Bar = styled.div`
  height: 3px;
  width: 100%;
  background-color: white;
`

const ExpandNavButton = (
  props: {
    active?: boolean;
    onClick?: () => void;
  }
) => {
  const { active = false, onClick } = props
  return (
    <Wrapper active={active} onClick={onClick}>
      <Bar />
      <Bar />
      <Bar />
    </Wrapper>
  )
}

export default ExpandNavButton