import styled from "styled-components"
import useUrlState from "../../../hooks/use-url-state"
import SelectField from "../../ui/select-field"
import { getErrorMessage, models, selectModelOptions, selectUfOptions, ufs } from "../../../utils"
import { useState } from "react"
import Modal from "../../ui/modal"
import { AttemptStatusItem, useAttemptStatus } from "../../../hooks/report"
import AttemptReportItem from "./attempt-report-item"
import { AttemptReportItemData } from "./types"

const FormWrapper = styled.div`
  width: 100%;
  max-width: 1375px;
  display: flex;
  gap: 20px
`

const SelectFieldWrapper = styled.div<{ flex?: number }>`
  flex: ${props => props.flex || 1};
`

const Wrapper = styled.div`

`

const DateInputWrapper = styled.div`
  width: 193px;
  display: flex;
  flex-direction: column;
`

const DateInput = styled.input`
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  margin-top: 2px;
  border: solid 1px black;
`

const DateLabel = styled.label`
  font-weight: bolder;
  font-size: 14px;
`

const Button = styled.button`
  height: 43px;
  align-self: flex-end;
  background-color: white;
  border: solid 1px black;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bolder;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
`

const ReportWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px 0 -15px;
`

const AttemptStatus = () => {

  const [uf, setUf] = useUrlState("uf", null)
  const [inputUf, setInputUf] = useState<string | null>()
  const [model, setModel] = useUrlState("model", null)
  const [inputModel, setInputModel] = useState<string | null>()
  const [startDate, setStartDate] = useUrlState("startDate", null);
  const [formattedStartDate, setFormattedStartDate] = useState<string>();
  const [endDate, setEndDate] = useUrlState("endDate", null);
  const [formattedEndDate, setFormattedEndDate] = useState<string>();

  const [ isLoading, setIsLoading ] = useState<Boolean>(true)

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [dateErrorMessage, setDateErrorMessage] = useState<string>()

  const { data, error } = useAttemptStatus({ uf, model, startDate, endDate })

  const report = makeReport(data)

  const dataFilter = () => {
    if (formattedStartDate && formattedEndDate) {
      const currentDate = new Date(formatDate(String(new Date())).split(' ')[0])
      const initialDate = new Date(formattedStartDate)
      const finalDate = new Date(formattedEndDate)

      if (initialDate > currentDate || finalDate > currentDate) {
        setDateErrorMessage("Erro no filtro de datas!\nAs datas não podem estar no futuro.")
        setOpenModal(true)
      } else if (initialDate > finalDate) {
        setDateErrorMessage("Erro no filtro de datas!\nA data inicial não pode ser maior do que a data final.")
        setOpenModal(true)
      } else if (initialDate <= finalDate) {
        setStartDate(formattedStartDate)
        setEndDate(formattedEndDate)
      }
    }

    inputUf ? setUf(inputUf) : setUf(null)
    inputModel ? setModel(inputModel) : setModel(null)
  }
  
  if (isLoading) {
    setInputUf(uf)
    setInputModel(model)
    setIsLoading(false)
  }

  return (
    <Wrapper>
      <Modal open={openModal} type="ALERT" onClose={() => setOpenModal(false)}><strong style={{ whiteSpace: 'pre-line', textAlign: "center" }}>{dateErrorMessage}</strong></Modal>
      <FormWrapper>
        <SelectFieldWrapper>
          <SelectField
            label="UF"
            options={selectUfOptions}
            onChange={setInputUf}
            value={inputUf}
          />
        </SelectFieldWrapper>
        <SelectFieldWrapper>
          <SelectField
            label="Modelo"
            options={selectModelOptions}
            onChange={setInputModel}
            value={inputModel}
          />
        </SelectFieldWrapper>
        <DateInputWrapper>
          <DateLabel htmlFor="Data Inicial">Data Inicial</DateLabel>
          <DateInput
            type="date"
            name="Data Inicial"
            value={formattedStartDate || ""}
            onChange={(e) => setFormattedStartDate(e.target.value)}
          />
        </DateInputWrapper>
        <DateInputWrapper>
          <DateLabel htmlFor="Data Final">Data Final</DateLabel>
          <DateInput
            type="date"
            name="Data Final"
            value={formattedEndDate || ""}
            onChange={(e) => setFormattedEndDate(e.target.value)}
          />
        </DateInputWrapper>
        <Button style={{ width: "150px" }} onClick={() => dataFilter()}>Filtrar</Button>
      </FormWrapper>
      {
        error ? getErrorMessage(error) : null
      }
      {
        !report ? (
          <div style={{ padding: "10px 0" }}>
            Carregando...
          </div>
        ) : (
          <ReportWrapper>
            {
              report.map(report => <AttemptReportItem
                key={`${report.uf}:${report.model}`}
                report={report}
                startDate={startDate}
                endDate={endDate}
              />)
            }
          </ReportWrapper>
        )
      }
    </Wrapper>
  )
}

const makeReportTitle = (item: AttemptStatusItem) => {
  const ufName = ufs.find(uf => uf.ibgeCode === item.uf)?.name || item.uf
  const modelName = models.find(model => model.code === item.model)?.name || item.model
  return ufName + " - " + modelName
}

function makeReport(data: AttemptStatusItem[] | undefined): AttemptReportItemData[] | undefined {

  if (!data) return

  const ufModelDict: Record<string, AttemptReportItemData> = {}

  for (const item of data) {
    const key = `${item.uf}:${item.model}`
    if (!ufModelDict[key]) {
      ufModelDict[key] = {
        name: makeReportTitle(item),
        uf: item.uf,
        model: item.model,
        items: [],
        processed: item.processed,
        total: item.total
      }
    }
    ufModelDict[key].items.push({
      status: item.lastExtractionResult,
      amount: item.amount,
      avgLateness: item.lateness,
      avgExtractionTimeMs: item.avgExtractionTimeMs
    })
  }

  return Object.values(ufModelDict).sort((a, b) => {
    const aAmount = a.items.map(i => i.amount).reduce((a, b) => a + b, 0)
    const bAmount = b.items.map(i => i.amount).reduce((a, b) => a + b, 0)
    return bAmount - aAmount
  })

}

function formatDate(dateString?: string): string {
  if (!dateString) {
    return "Não processada"
  }

  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export default AttemptStatus