import styled, { css } from "styled-components"
import { InfrastructureItem, useInfrastructure } from "../../../hooks/report"
import { getErrorMessage } from "../../../utils"

const DashboardTableWrapper = styled.div`
  max-height: 420px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  flex: 2;
  border: 1px solid #808080;
  border-radius: 5px;
  box-sizing: border-box;
  
  @media (max-width: 1600px) {
    width: 100%;
    flex: none;
  }
`

const DashboardTableTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #808080;
  padding-bottom: 10px;
  margin-bottom: 10px;
`

const DashboardTableTitle = styled.h2<{ statusDown: boolean }>`
  font-size: 20px;
  font-weight: 700;
  line-height: 121.83%;
  margin: 0;
  ${({ statusDown }) => statusDown && css`
    color: #FF0000;
  `}
`

const DashboardTableSubtitle = styled.p<{ statusDown: boolean }>`
  font-size: 13px;

  ${({ statusDown }) => statusDown && css`
    color: #FF0000;
  `}
`

const InfrastructureDataBox = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  color: white;
  font-size: 12px;
  font-weight: bolder;
  overflow-y: auto;

  @media (max-width: 800px) {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  
  .title {
    height: 40px;
    display: flex;
    align-items: center;
  }
  
  p {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    color: #fff
  }
  
  #loading {
    font-size: 14px;
    font-weight: 500;
    color: black;
  }
  
  span {
    font-size: 10px;
    font-weight: 500;
    color: #fff
  }
`

const InfrastructureBoxItem = styled.div<{ statusDown: boolean }>`
  width: 120px;
  flex-shrink: 0;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding: 10px;
  border: 1px solid ${({ statusDown }) => (statusDown ? 'red' : 'green')};
  border-radius: 5px;
  background-color: ${({ statusDown }) => (statusDown ? '#cf7272' : '#74b165')};
  font-size: 14px;
`

function formatDate(dateString: string): string {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

const Infrastructure = () => {
  const { data, error } = useInfrastructure()

  const total = !data ? 0 : data.services.length
  const up = !data ? 0 : data.services.filter(i => !isDown(i, data.now)).length

  return (
    <DashboardTableWrapper>
      <DashboardTableTitleWrapper>
        <DashboardTableTitle statusDown={(up < total)}>
          Infraestrutura
        </DashboardTableTitle>
        <DashboardTableSubtitle statusDown={(up < total)}>
          {`${up}/${total}`} Serviços Ativos
        </DashboardTableSubtitle>
      </DashboardTableTitleWrapper>
      <InfrastructureDataBox>
        {
          !error ? (
            data ? data.services.map(item => {
              return (
                <InfrastructureBoxItem
                  key={item.description}
                  statusDown={isDown(item, data.now)}>
                  <div className="title">
                    {item.description}
                  </div>
                  <p>
                    Último relatório:
                    <span>{formatDate(item.lastReportDt)}</span>
                  </p>
                </InfrastructureBoxItem>
              )
            }) : (<h1 id="loading">Carregando...</h1>)
          ) : (
            <div>
              {getErrorMessage(error)}
            </div>
          )
        }
      </InfrastructureDataBox>
    </DashboardTableWrapper>
  )
}

function isDown(item: InfrastructureItem, now: Date): boolean {

  if (item.lastStatus === "DOWN") {
    return true
  }

  const lastReportDt = new Date(item.lastReportDt)
  const nextReportDt = new Date(item.nextReportDt)

  return nextReportDt < now && lastReportDt < nextReportDt

}

export default Infrastructure