import styled from "styled-components"
import { ReactNode } from "react"
import SideMenu from "./side-menu"
import TopMenu from "./top-menu"

const Wrapper = styled.div`
  width: 100%;
  padding-left: 250px;
  box-sizing: border-box;
  display: flex;
  @media (max-width: 1600px) {
    padding-top: 55px;
    padding-left: 0;
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`

type Props = {
  children?: ReactNode
}

const Layout = (props: Props) => {
  return (
    <Wrapper>
      <TopMenu />
      <SideMenu />
      <Body>
        <Content>
          {props.children}
        </Content>
      </Body>
    </Wrapper>
  )
}

export default Layout