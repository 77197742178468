import styled from "styled-components"

const Table = styled.table<{ mainColumn: number }>`
  border-collapse: collapse;
  td, th {
    white-space: nowrap;
    font-size: 14px;
    padding: 10px;
    text-align: center;
  }
  th {
    background-color: #0B1045;
    color: white;
  }
  td:nth-child(${props => props.mainColumn}),
  th:nth-child(${props => props.mainColumn}) {
    text-align: left;
    width: 100%;
  }
  tr:nth-child(2n) td {
    background-color: #F2F2F2;
  }
`

export default Table