import { useReceiptAttempts } from "../../../hooks/receipts"
import Table from "../../ui/table"
import useUrlState from "../../../hooks/use-url-state"
import { useProjects } from "../../../hooks/projects"
import SelectField from "../../ui/select-field"
import { getErrorMessage, selectModelOptions, selectUfOptions } from "../../../utils"
import styled from "styled-components"
import TextField from "../../ui/text-field"
import { useEffect, useRef, useState } from "react"
import Pagination from "../../ui/pagination"
import Modal from "../../ui/modal"
import AttemptTableRow from "./attempt-table-row"

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  overflow-x: auto;
`

const SelectFieldWrapper = styled.div<{ flex?: number }>`
  flex: ${props => props.flex || 1};
`

const DateInputWrapper = styled.div`
  width: 193px;
  display: flex;
  flex-direction: column;
`
const DateInput = styled.input`
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  margin-top: 2px;
  border: solid 1px black;
`
const DateLabel = styled.label`
  font-weight: bolder;
  font-size: 14px;
`

const Button = styled.button`
  height: 43px;
  align-self: flex-end;
  background-color: white;
  border: solid 1px black;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bolder;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
`

const AccessKeyFieldWrapper = styled.div`
  min-width: 300px;
`

const ResponsiveTable = styled.div`
  width: 100%;
  overflow-x: auto;
`

const AttemptSearch = () => {

  const { data: projects, error, isLoading } = useProjects()

  const accessKeyTimeoutHandle = useRef<any>()

  const [accessKey, setAccessKey] = useUrlState("accessKey", null)
  const [projectId, setProjectId] = useUrlState("projectId", null)
  const [inputProjectId, setInputProjectId] = useState<string | null>()
  const [uf, setUf] = useUrlState("uf", null)
  const [inputUf, setInputUf] = useState<string | null>()
  const [model, setModel] = useUrlState("model", null)
  const [inputModel, setInputModel] = useState<string | null>()
  const [result, setResult] = useUrlState("result", null)
  const [inputResult, setInputResult] = useState<string | null>()
  const [page, setPage] = useUrlState("activePage", null)
  const [currentPage, setCurrentPage] = useState<string | null>()
  const [startDate, setStartDate] = useUrlState("startDate", null);
  const [endDate, setEndDate] = useUrlState("endDate", null);
  const [formattedStartDate, setFormattedStartDate] = useState<string | null>();
  const [formattedEndDate, setFormattedEndDate] = useState<string | null>();

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [dateErrorMessage, setDateErrorMessage] = useState<string>()

  const [formAccessKey, setFormAccessKey] = useState<string | null>(accessKey)

  const projectOptions = projects ? [
    {
      value: null,
      description: "Todos"
    },
    ...projects
      .map(p => ({ value: String(p.id), description: p.name }))
      .sort((a, b) => a.description.localeCompare(b.description))
  ] : [
    {
      value: null,
      description: "Carregando..."
    }
  ]

  const dataFilter = () => {
    if (formattedStartDate && formattedEndDate) {
      const currentDate = new Date(formatDate(String(new Date())).split(' ')[0])
      const initialDate = new Date(formattedStartDate)
      const finalDate = new Date(formattedEndDate)

      if (initialDate > currentDate || finalDate > currentDate) {
        setDateErrorMessage("Erro no filtro de datas!\nAs datas não podem estar no futuro.")
        setOpenModal(true)
      } else if (initialDate > finalDate) {
        setDateErrorMessage("Erro no filtro de datas!\nA data inicial não pode ser maior do que a data final.")
        setOpenModal(true)
      } else if (initialDate <= finalDate) {
        setStartDate(formattedStartDate)
        setEndDate(formattedEndDate)
      }
    }

    setAccessKey(formAccessKey)
    inputProjectId ? setProjectId(inputProjectId) : setProjectId(null)
    inputResult ? setResult(inputResult) : setResult(null)
    inputUf ? setUf(inputUf) : setUf(null)
    inputModel ? setModel(inputModel) : setModel(null)
  }

  const {
    data: queue
  } = useReceiptAttempts({
    projectId: projectId ? parseInt(projectId) : null, uf, model, accessKey, result, currentPage, startDate, endDate
  })

  useEffect(() => {
    
    clearTimeout(accessKeyTimeoutHandle.current)

    accessKeyTimeoutHandle.current = setTimeout(() => setFormAccessKey(formAccessKey), 1500)

    return () => clearTimeout(accessKeyTimeoutHandle.current)

  }, [formAccessKey, accessKeyTimeoutHandle])

  useEffect(() => {
    setInputUf(uf)
    setInputModel(model)
    setFormattedStartDate(startDate)
    setFormattedEndDate(endDate)
    setCurrentPage(page)
  }, [page, uf, model, startDate, endDate])

  return (
    <>
      <Modal open={openModal} type="ALERT" onClose={() => setOpenModal(false)}><strong style={{ whiteSpace: 'pre-line', textAlign: "center" }}>{dateErrorMessage}</strong></Modal>
      <FormWrapper>
        <AccessKeyFieldWrapper>
          <TextField
            label="Chave de Acesso"
            placeholder="Chave de Acesso"
            value={formAccessKey}
            onChange={setFormAccessKey}
          />
        </AccessKeyFieldWrapper>
        <SelectFieldWrapper>
          <SelectField
            label="Projeto"
            options={projectOptions}
            value={inputProjectId ? String(inputProjectId) : null}
            onChange={setInputProjectId}
          />
        </SelectFieldWrapper>
        <SelectFieldWrapper>
          <SelectField
            label="Resultado"
            options={[
              { value: null, description: "Todos" },
              { value: "ACCESS_DENIED", description: "ACCESS_DENIED" },
              { value: "ACCESSKEY_UNAVAILABLE", description: "ACCESSKEY_UNAVAILABLE" },
              { value: "CAPTCHA_BREAKER_INSUFFICIENT_FUNDS", description: "CAPTCHA_BREAKER_INSUFFICIENT_FUNDS" },
              { value: "CAPTCHA_BREAKER_TIMEOUT", description: "CAPTCHA_BREAKER_TIMEOUT" },
              { value: "CAPTCHA_BREAKER_UNAVAILABLE", description: "CAPTCHA_BREAKER_UNAVAILABLE" },
              { value: "CAPTCHA_BREAKER_WRONG_RESPONSE", description: "CAPTCHA_BREAKER_WRONG_RESPONSE" },
              { value: "CANCELED_RECEIPT", description: "CANCELED_RECEIPT" },
              { value: "CONTINGENCY_RECEIPT", description: "CONTINGENCY_RECEIPT" },
              { value: "CONTINGENCY_ERROR", description: "CONTINGENCY_ERROR" },
              { value: "EXTRACTION_TIMEOUT", description: "EXTRACTION_TIMEOUT" },
              { value: "NOT_FOUND_ACCESS_KEY", description: "NOT_FOUND_ACCESS_KEY" },
              { value: "OK", description: "OK" },
              { value: "UNHANDLED_ERROR", description: "UNHANDLED_ERROR" },
              { value: "PAGE_UNAVAILABLE", description: "PAGE_UNAVAILABLE" },
              { value: "UNSUPPORTED_ACCESSKEY", description: "UNSUPPORTED_ACCESSKEY" },
              { value: "VALIDATION_ERROR", description: "VALIDATION_ERROR" },
            ]}
            value={inputResult}
            onChange={setInputResult}
          />
        </SelectFieldWrapper>
        <SelectFieldWrapper>
          <SelectField
            label="UF"
            options={selectUfOptions}
            onChange={setInputUf}
            value={inputUf}
          />
        </SelectFieldWrapper>
        <SelectFieldWrapper>
          <SelectField
            label="Modelo"
            options={selectModelOptions}
            onChange={setInputModel}
            value={inputModel}
          />
        </SelectFieldWrapper>
      </FormWrapper>
      <FormWrapper>
        <DateInputWrapper>
          <DateLabel htmlFor="Data Inicial">Data Inicial</DateLabel>
          <DateInput
            type="date"
            name="Data Inicial"
            value={formattedStartDate || ""}
            onChange={(e) => setFormattedStartDate(e.target.value)}
          />
        </DateInputWrapper>
        <DateInputWrapper>
          <DateLabel htmlFor="Data Final">Data Final</DateLabel>
          <DateInput
            type="date"
            name="Data Final"
            value={formattedEndDate || ""}
            onChange={(e) => setFormattedEndDate(e.target.value)}
          />
        </DateInputWrapper>
        <Button style={{ width: "150px" }} onClick={() => dataFilter()}>Filtrar</Button>
      </FormWrapper>
      {
        isLoading ? "Carregando..." : null
      }
      {
        error ? getErrorMessage(error) : null
      }
      {
        !queue ? null : (
          <>
            <ResponsiveTable>
              <Table mainColumn={2}>
                <thead>
                  <tr>
                    <th>Projeto</th>
                    <th>Chave de Acesso</th>
                    <th>Máquina</th>
                    <th>Resultado</th>
                    <th>Data</th>
                    <th>Opções</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    queue.result.map(item => (
                      <AttemptTableRow
                        key={`${item.projectId}:${item.accessKey}:${item.attemptDt}`}
                        id={item.id}
                        projectId={item.projectId}
                        projectName={item.projectName}
                        workerName={item.workerName}
                        attemptDt={formatDate(item.attemptDt)}
                        result={item.result}
                        accessKeyCode={item.accessKey}
                        hasJson={item.hasJson}
                        hasHtml={item.hasHtml}
                        hasStacktrace={item.hasStacktrace}
                        hasScreenshot={item.hasScreenshot}
                      />
                    ))
                  }
                </tbody>
              </Table>
            </ResponsiveTable>
            <Pagination total={queue.total} page={Number(currentPage)} onChange={(newPage) => setPage(String(newPage))} />
          </>
        )
      }
    </>
  )
}

function formatDate(dateString?: string): string {
  if (!dateString) {
    return "Não processada"
  }

  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export default AttemptSearch