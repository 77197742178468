import styled from "styled-components"
import Column from "../../ui/column"
import Title from "../../ui/title"
import Layout from "../layout/layout"
import withRedirectUnauthenticated from "../../../hocs/with-redirect-unauthenticated"
import { Outlet } from "react-router"
import NavButton from "../../ui/nav-button"

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 15px;
  @media (max-width: 800px) {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
`

const DashboardPage = () => {
  return (
    <Layout>
      <Column padding="15px" gap="15px">
        <Column>
          <Title>
            Dashboard
          </Title>
        </Column>
        <ButtonsWrapper>
          <NavButton path="/dashboard">
            Visão Geral
          </NavButton>
          <NavButton path="/dashboard/queue-status">
            Status da Fila
          </NavButton>
          <NavButton path="/dashboard/attempt-status">
            Status de Tentativas
          </NavButton>
          <NavButton path="/dashboard/extractor-performance">
            Performance de Extratores
          </NavButton>
          <NavButton path="/dashboard/import-performance">
            Performance de Importação
          </NavButton>
          <NavButton path="/dashboard/queue-search">
            Consulta de Fila
          </NavButton>
          <NavButton path="/dashboard/attempt-search">
            Consulta de Tentativas
          </NavButton>
        </ButtonsWrapper>
        <Outlet />
      </Column>
    </Layout>
  )
}

export default withRedirectUnauthenticated(DashboardPage)