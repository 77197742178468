import { ReactNode, useEffect } from "react"
import styled from "styled-components"
import Button from "./button";
import Row from "./row";
import ReactDOM from "react-dom";

type Props = {
  open?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  children?: ReactNode;
  type?: "ALERT" | "CONFIRM" | "DANGER" | "BLANK"
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9000;
`

const CenterBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 200px;
  max-width: 90vw;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`

const Modal = (props: Props) => {
  const {
    open = false,
    children,
    onConfirm,
    onClose: baseOnClose,
    type = "ALERT"
  } = props

  const onClose = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      baseOnClose?.()
    }
  }

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        baseOnClose?.()
      }
    }

    document.addEventListener('keydown', handleEscape);

    return () => document.removeEventListener('keydown', handleEscape)
  }, [ baseOnClose ]);

  if (!open) {
    return null
  }

  return ReactDOM.createPortal(
    <Wrapper onClick={onClose}>
      <CenterBox>
        <ContentWrapper>
          {children}
        </ContentWrapper>
        {
          type !== "ALERT" ? null : (
            <Row align="center">
              <Button onClick={onClose}>
                Ok
              </Button>
            </Row>
          )
        }
        {
          type !== "CONFIRM" ? null : (
            <Row align="center" gap="15px">
              <Button onClick={onConfirm}>
                Ok
              </Button>
              <Button onClick={onClose} layout="secondary">
                Cancelar
              </Button>
            </Row>
          )
        }
        {
          type !== "DANGER" ? null : (
            <Row align="center" gap="15px">
              <Button onClick={onConfirm} layout="danger">
                Ok
              </Button>
              <Button onClick={onClose} layout="secondary">
                Cancelar
              </Button>
            </Row>
          )
        }
      </CenterBox>
    </Wrapper>,
    document.getElementById("modal")!
  )
}

export default Modal