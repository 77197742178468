import { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "../../ui/modal";
import { useReceiptAttemptItemData } from "../../../hooks/receipts";

const Button = styled.button`
  background-color: white;
  border: solid 1px black;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bolder;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
`

const StacktraceWrapper = styled.pre`
  width: 100%;
  max-height: 400px;
  background-color: black;
  padding: 20px 10px;
  color: white;
  overflow: auto;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`

const AttemptScreenshotImg = styled.img`
  border: solid 1px black;
  box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
  max-height: 90vh;
  max-width: 90vw;
`

const Loading = styled.p`
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
`

type Props = {
  id: number;
  selectedData: string;
}

const AttemptTableData = (props: Props) => {
  const [openModalJson, setOpenModalJson] = useState<boolean>(false)
  const [openModalHtml, setOpenModalHtml] = useState<boolean>(false)
  const [openModalStacktrace, setOpenModalStacktrace] = useState<boolean>(false)
  const [openModalScreenshot, setOpenModalScreenshot] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(true);

  const [json, setJson] = useState<String | null>(null)
  const [html, setHtml] = useState<String | null>(null)
  const [stacktrace, setStacktrace] = useState<String | null>(null)
  const [screenshot, setScreenshot] = useState<String | null>(null)

  const {
    data: attemptData
  } = useReceiptAttemptItemData({
    id: props.id
  })

  useEffect(() => {
    if (attemptData) {
      formatJson(attemptData.json).includes('"json": null') ? setJson(null) : setJson(formatJson(attemptData.json));
      setHtml(attemptData.html);
      setStacktrace(attemptData.stacktrace);
      setScreenshot(attemptData.screenshotBase64)
      setLoading(false);
    }
  }, [attemptData]);

  useEffect(() => {
    if (!loading) {
      setOpenModalJson(props.selectedData === "json");
      setOpenModalHtml(props.selectedData === "html");
      setOpenModalStacktrace(props.selectedData === "stacktrace");
      setOpenModalScreenshot(props.selectedData === "screenshot");
    }
  }, [loading, props.selectedData]);


  return (
    <>
      <Modal open={openModalJson} type="ALERT" onClose={() => setOpenModalJson(false)}><strong style={{ whiteSpace: 'pre-line', textAlign: "center" }}></strong>
        <StacktraceWrapper>
          {json}
        </StacktraceWrapper>
      </Modal>
      <Modal open={openModalHtml} type="ALERT" onClose={() => setOpenModalHtml(false)}><strong style={{ whiteSpace: 'pre-line', textAlign: "center" }}></strong>
        <StacktraceWrapper>
          {html}
        </StacktraceWrapper>
      </Modal>
      <Modal open={openModalStacktrace} type="ALERT" onClose={() => setOpenModalStacktrace(false)}><strong style={{ whiteSpace: 'pre-line', textAlign: "center" }}></strong>
        <StacktraceWrapper>
          {stacktrace}
        </StacktraceWrapper>
      </Modal>
      <Modal open={openModalScreenshot} type="ALERT" onClose={() => setOpenModalScreenshot(false)}><strong style={{ whiteSpace: 'pre-line', textAlign: "center" }}></strong>
        <AttemptScreenshotImg src={ "data:image/png;base64," + screenshot }/>
      </Modal>

      {loading ? (
        <Loading>Carregando...</Loading>
      ) : (
        <ButtonsWrapper>
          {json ? <Button onClick={() => setOpenModalJson(true)}>Json</Button> : null}
          {html ? <Button onClick={() => setOpenModalHtml(true)}>Html</Button> : null}
          {stacktrace ? <Button onClick={() => setOpenModalStacktrace(true)}>Log</Button> : null}
          {screenshot ? <Button onClick={() => setOpenModalScreenshot(true)}>Screenshot</Button> : null}
        </ButtonsWrapper>
      )}
    </>
  )
}

function formatJson(json?: any): string {
  if (!json) return ""
  try {
    return JSON.stringify(json, null, 4);
  } catch (e: any) {
    return e.message
  }
}

export default AttemptTableData