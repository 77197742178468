import { AttemptReportItemData, AttemptReportLineData } from "./types"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"

const Wrapper = styled.div`
  width: 33.333333333%;
  padding: 15px;
  box-sizing: border-box;
`

const Header = styled.div`
  border: solid 1px black;
  border-bottom: 0;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
`

const Title = styled.div`
  font-weight: bolder;
  font-size: 20px;
  flex: 1
`

const HeaderButton = styled.button`
  border: solid 1px black;
  border-radius: 5px;
  background-color: white;
  padding: 0 10px;
  height: 28px;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
`

const PercentsBar = styled.div`
  border: solid 1px black;
  border-bottom: 0;
  height: 20px;
`

const PercentsBarItem = styled.div`
  height: 100%;
  float: left;
`

const Legend = styled.div`
  width: 10px;
  height: 10px;
  border: solid 1px black;
  display: inline-block;
  margin-right: 5px;
`

const Table = styled.table`
  border-collapse: collapse;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  tr:nth-child(odd) td {
    background-color: #efefef;
  }
  td, th {
    text-align: center;
    border: solid 1px black;
    padding: 5px;
  }
  td:nth-child(1),
  th:nth-child(1) {
    text-align: left;
    width: 100%;
    padding-left: 10px;
  }
  td:last-child,
  th:last-child {
    padding-right: 10px;
  }
`

const colors: Record<string, string> = {
  OK: "green",
  CAPTCHA_BREAKER_INSUFFICIENT_FUNDS: "#FF6347",
  CAPTCHA_BREAKER_TIMEOUT: "#FFD700",
  CAPTCHA_BREAKER_UNAVAILABLE: "#FF4500",
  CAPTCHA_BREAKER_WRONG_RESPONSE: "#8A2BE2",
  NOT_FOUND_ACCESS_KEY: "#DC143C",
  CANCELED_RECEIPT: "#00CED1",
  CONTINGENCY_RECEIPT: "#FF69B4",
  UNHANDLED_ERROR: "#FF1493",
  VALIDATION_ERROR: "#FF8C00",
  UNSUPPORTED_ACCESSKEY: "#6A5ACD",
  ACCESS_DENIED: "#B22222",
  ACCESSKEY_UNAVAILABLE: "#20B2AA",
  PAGE_UNAVAILABLE: "#D2691E",
  EXTRACTION_TIMEOUT: "#4B0082",
  NO_ATTEMPTS: "lightblue",
  PENDING: "#00FFFF",
  CONTINGENCY_ERROR: "#FA8072",
  0: "green",
  1: "yellow",
  2: "orange",
  3: "purple",
  4: "red",
  5: "darkred"
}

function formatMilliseconds(ms: number) {
  const seconds = Math.floor(ms / 1000);
  return `${seconds}s`;
}

const AttemptReportItem = (
  props: { report: AttemptReportItemData, startDate: string | null, endDate: string | null }
) => {
  const groupKey = "status"
  const { report } = props

  const navigate = useNavigate()

  const total: AttemptReportLineData = {
    status: "TOTAL",
    amount: 0,
    avgExtractionTimeMs: 0,
    avgLateness: 0,
  }

  const groupedItems: AttemptReportLineData[] = []

  const groupDict: Record<string, AttemptReportLineData> = {}

  for (const item of report.items) {
    const key = item[groupKey]
    const newTotalAmount = total.amount + item.amount
    total.avgLateness =  (total.avgLateness * total.amount + item.avgLateness * item.amount) / newTotalAmount
    total.avgExtractionTimeMs =  (total.avgExtractionTimeMs * total.amount + item.avgExtractionTimeMs * item.amount) / newTotalAmount
    total.amount = newTotalAmount
    if (!groupDict[key]) {
      groupDict[key] = {
        status: item.status,
        amount: 0,
        avgLateness: 0,
        avgExtractionTimeMs: 0,
      }
      groupedItems.push(groupDict[key])
    }
    const groupedItem = groupDict[key]
    const newGroupedAmount = groupedItem.amount + item.amount
    groupedItem.avgLateness = (groupedItem.avgLateness * groupedItem.amount + item.avgLateness * item.amount) / newGroupedAmount
    groupedItem.avgExtractionTimeMs = (groupedItem.avgExtractionTimeMs * groupedItem.amount + item.avgExtractionTimeMs * item.amount) / newGroupedAmount
    groupedItem.amount = newGroupedAmount
  }

  const groupedItemsSorted = groupedItems.sort((a, b) => {
    if (a[groupKey] === "OK") {
      return -1
    }
    if (b[groupKey] === "OK") {
      return 1
    }
    return String(b.amount).localeCompare(String(a.amount))
  })

  const onDetailClick = () => {
    const params = new URLSearchParams()
    
    if (report.uf !== "Total") {
      params.set("uf", report.uf)
    }
    if (report.model !== "Total") {
      params.set("model", report.model)
    }

    const today = new Date().toISOString().split("T")[0];

    params.set("startDate", props.startDate || today);
    params.set("endDate", props.endDate || today);

    const path = '/dashboard/attempt-search?' + params

    navigate(path)
  }

  return (
    <Wrapper>
      <Header>
        <Title>
          {report.name}
        </Title>
        <HeaderButton onClick={onDetailClick}>
          Detalhes
        </HeaderButton>
      </Header>
      <PercentsBar>
        {
          groupedItemsSorted.map(item => (
            <PercentsBarItem
              key={item[groupKey]}
              style={{
                width: `${(item.amount / total.amount) * 100}%`,
                backgroundColor: colors[item[groupKey]]
              }}
            />
          ))
        }
      </PercentsBar>
      <Table>
        <thead>
          <tr>
            <th>Status</th>
            <th>QTD</th>
            <th>PER</th>
            <th>DUR</th>
          </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            TOTAL
          </td>
          <td>
            {total.amount}
          </td>
          <td>
            --
          </td>
          <td>
            {formatMilliseconds(total.avgExtractionTimeMs)}
          </td>
        </tr>
        {
          groupedItemsSorted.map(item => (
            <tr key={item[groupKey]}>
              <td>
                <Legend
                  style={{ backgroundColor: colors[item[groupKey]] }}
                />
                { formatTitle(item[groupKey]) }
              </td>
              <td>
                { item.amount }
              </td>
              <td>
              { Math.max(Math.round((item.amount / total.amount) * 100), 1) }%
              </td>
              <td>
                { formatMilliseconds(item.avgExtractionTimeMs) }
              </td>
            </tr>
          ))
        }
        </tbody>
      </Table>
    </Wrapper>
  )
}

function formatTitle(title: any) {
  if (String(title) === "5") {
    return "5+"
  }
  return String(title).replaceAll("_", " ")
}

export default AttemptReportItem