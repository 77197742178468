import styled from "styled-components"
import useUrlState from "../../../hooks/use-url-state"
import SelectField from "../../ui/select-field"
import { getErrorMessage, models, selectModelOptions, selectUfOptions, ufs } from "../../../utils"
import { useProjects } from "../../../hooks/projects"
import { QueueStatusItem, useQueueStatus } from "../../../hooks/report"
import { QueueReportItemData } from "./types"
import QueueReportItem from "./queue-report-item"

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px
`

const SelectFieldWrapper = styled.div<{ flex?: number }>`
  flex: ${props => props.flex || 1};
`

const ProjectFieldWrapper = styled.div`
  flex: 4;
  @media (max-width: 800px) {
    flex: unset;
    width: 100%;
  }
`

const Wrapper = styled.div`
`

const ReportWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px 0 -15px;
`

const QueueStatus = () => {

  const { data: projects } = useProjects()

  const [ projectId, setProjectId ] = useUrlState("projectId", null)
  const [ uf, setUf ] = useUrlState("uf", null)
  const [ model, setModel ] = useUrlState("model", null)

  const { data, error } = useQueueStatus({ projectId: projectId ? parseInt(projectId) : null, uf, model })

  const report = makeReport(data)

  const projectOptions = projects ? [
    {
      value: null,
      description: "Todos"
    },
    ...projects
      .map(p => ({ value: String(p.id), description: p.name }))
      .sort((a, b) => a.description.localeCompare(b.description))
  ] : [
    {
      value: null,
      description: "Carregando..."
    }
  ]

  return (
    <Wrapper>
      <FormWrapper>
        <ProjectFieldWrapper>
          <SelectField
            label="Projeto"
            options={projectOptions}
            value={projectId ? String(projectId) : null}
            onChange={setProjectId}
          />
        </ProjectFieldWrapper>
        <SelectFieldWrapper>
          <SelectField
            label="UF"
            options={selectUfOptions}
            onChange={setUf}
            value={uf}
          />
        </SelectFieldWrapper>
        <SelectFieldWrapper>
          <SelectField
            label="Modelo"
            options={selectModelOptions}
            onChange={setModel}
            value={model}
          />
        </SelectFieldWrapper>
      </FormWrapper>
      {
        error ? getErrorMessage(error) : null
      }
      {
        !report ? (
          <div style={{ padding: "10px 0" }}>
            Carregando...
          </div>
        ) : (
          <ReportWrapper>
            {
              report.map(report => <QueueReportItem
                key={`${report.uf}:${report.model}`}
                report={report}
                projectId={projectId ? parseInt(projectId) : null}
              />)
            }
          </ReportWrapper>
        )
      }
    </Wrapper>
  )
}

const makeReportTitle = (item: QueueStatusItem) => {
  const ufName = ufs.find(uf => uf.ibgeCode === item.uf)?.name || item.uf
  const modelName = models.find(model => model.code === item.model)?.name || item.model
  return ufName + " - " + modelName
}

function makeReport(data: QueueStatusItem[] | undefined): QueueReportItemData[] | undefined {

  if (!data) return

  const ufModelDict: Record<string, QueueReportItemData> = {}

  for (const item of data) {
    const key = `${item.uf}:${item.model}`
    if (!ufModelDict[key]) {
      ufModelDict[key] = {
        name: makeReportTitle(item),
        uf: item.uf,
        model: item.model,
        items: [],
        processed: item.processed,
        total: item.total
      }
    }
    ufModelDict[key].items.push({
      status: item.lastExtractionResult,
      amount: item.amount,
      avgExtractions: item.avgExtractions,
      avgLateness: item.lateness,
      avgExtractionTimeMs: item.avgExtractionTimeMs
    })
  }

  return Object.values(ufModelDict).sort((a, b) => {
    const aAmount = a.items.map(i => i.amount).reduce((a, b) => a + b, 0)
    const bAmount = b.items.map(i => i.amount).reduce((a, b) => a + b, 0)
    return bAmount - aAmount
  })

}

export default QueueStatus