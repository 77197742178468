import React, { ReactNode } from "react"
import styled from "styled-components"
import { useLocation, useNavigate } from "react-router-dom"

type Props = {
  children?: ReactNode;
  path: string;
}

const Wrapper = styled.a<{ active: boolean }>`
  padding: 0 20px;
  border-radius: 5px;
  font-size: 14px;
  height: 40px;
  border: solid 1px black;
  font-weight: bolder;
  display: flex;
  align-items: center;
  color: black;
  text-decoration: none;
  box-sizing: border-box;
  white-space: nowrap;
  ${props => {
    if (props.active) {
      return `
        border: solid 1px #0B1045;
        background-color: #0B1045;
        color: white;
      `
    }
    return `
      &:hover {
        background-color: lightgray;
      }
    `
  }}
`

const NavButton = (props: Props) => {
  const {
    children, path
  } = props

  const location = useLocation()
  const navigate = useNavigate()

  const handleOnClick = (e: React.MouseEvent) => {
    e.preventDefault()
    navigate(path)
  }

  return (
    <Wrapper
      href={path}
      active={location.pathname === path}
      onClick={handleOnClick}
      children={children} />
  )
}

export default NavButton