import styled from "styled-components"
import TextField from "../../ui/text-field"
import Button from "../../ui/button"
import { useEffect, useState } from "react"

const RegisterCustomerWrapper = styled.div`
  padding: 14px;
  display: flex;
  flex-direction: column;
  border: 1px solid #808080;
  border-radius: 5px;
`

const RegisterCustomerTitle = styled.h1`
  width: 100%;
  padding-bottom: 14px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid #808080;
`

const RegisterCustomerForm = styled.form`
  width: 50%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const CustomerRegister = () => {
  const [ clientName, setClientName ] = useState<string | null>()
  const [ clientNameValidation, setClientNameValidation] = useState<string | undefined>()
  const [ clientEmail, setClientEmail ] = useState<string | null>()
  const [ clientEmailValidation, setClientEmailValidation ] = useState<string | undefined>()
  const [ userName, setUsername ] = useState<string | null>()
  const [ userPassword, setUserPassword ] = useState<string | null>()
  const [ formDisabled, setFormDisabled ] = useState<boolean>(true)

 useEffect(() => {
  const formValidation = () => {
    if(!clientName || !clientEmail || !userName || !userPassword) {
      setFormDisabled(true)
    } else {
      setFormDisabled(false)
    }
  }
  formValidation()
 }, [clientName, clientEmail, userName, userPassword])

  return (
    <RegisterCustomerWrapper>
      <RegisterCustomerTitle>
        Cadastrar Cliente
      </RegisterCustomerTitle>
      <RegisterCustomerForm>
        <TextField
          type="text"
          label="Nome"
          placeholder="Nome do Cliente"
          value={clientName}
          onChange={(newValue) => {setClientName(newValue);setClientNameValidation(clientName ? undefined : "Nome do Cliente Necessário")}}
          validationMessage={clientNameValidation}
          />
        <TextField
          type="text"
          label="Email"
          placeholder="Email"
          value={clientEmail}
          onChange={(newValue) => {setClientEmail(newValue); setClientEmailValidation(newValue ? undefined : "Email Necessário")}}
          validationMessage={clientEmailValidation}
        />
        <TextField
          type="text"
          label="Nome de Usuário"
          placeholder="Nome de Usuário"
          value={userName}
          onChange={(newValue) => setUsername(newValue)}
          validationMessage="Nome de Usuário Necessário"
        />
        <TextField
          type="password"
          label="Senha"
          placeholder="Senha"
          value={userPassword}
          onChange={(newValue) => setUserPassword(newValue)}
          validationMessage="Senha Necessária"
        />
        <Button width={160} type="submit" disabled={formDisabled}>
          Salvar Cliente
        </Button>
      </RegisterCustomerForm>
    </RegisterCustomerWrapper>
  )
}

export default CustomerRegister