import styled from "styled-components"
import Table from "../../ui/table"
import SelectField from "../../ui/select-field"
import { useState } from "react"
import Pagination from "../../ui/pagination"

const ClientTableWrapper = styled.div`
  max-height: 420px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid #808080;
  border-radius: 5px;

  ::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}
`

const ClientTableTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #808080;
  margin-bottom: 10px;
`

const ClientTableTitle = styled.h2`
  font-size: 20px;
  font-weight: 700;
  line-height: 121.83%;
  margin: 0 0 10px 0;
`

const TableWrapper = styled.div`
  height: 100%;
  overflow: auto;
`

const StyledTable = styled(Table)`
  th {
    position: sticky;
    top: 0;
  }
`

const SelectFieldWrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;
`

type SelectOptions = {
  value: string;
  description: string;
}

const ProjectName: SelectOptions[] = [
  { value: 'Mandarim', description: 'Mandarim' },
  { value: 'Mandarim 2', description: 'Mandarim 2' },
  { value: 'Mandarim 3', description: 'Mandarim 3' },
];

const ClientsList = () => {
  const [ projectNameSelected, setProjectNameSelected ] = useState("Mandarim")
  
  const handleProjectNameChange = (value: string | null) => {
    if(!value) {
      value = "Mandarim"
    }
    setProjectNameSelected(value)
  }
  
  return (
    <ClientTableWrapper>
      <ClientTableTitleWrapper>
        <ClientTableTitle>
          Clientes
        </ClientTableTitle>
      </ClientTableTitleWrapper>
      <SelectFieldWrapper>
        <SelectField 
          options={ProjectName}
          value={projectNameSelected}
          onChange={handleProjectNameChange}
        />
      </SelectFieldWrapper>
      <TableWrapper>
        <StyledTable mainColumn={1}>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Nome</td>
              <td>Ver Detalhes</td>
            </tr>
          </tbody>
        </StyledTable>
        <Pagination 
          page={1}
          total={20}
          onChange={() => console.log("rodei")}
        />
      </TableWrapper>
    </ClientTableWrapper>
  )
}

export default ClientsList