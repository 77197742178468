import { RejectedHttpResponseError } from "./hooks/use-request"

export function getAuthToken(): string | null {
  return localStorage.getItem("SESSION_TOKEN")
}

export function setAuthToken(token: string | null) {
  if (!token) {
    localStorage.removeItem("SESSION_TOKEN")
    return
  }
  localStorage.setItem("SESSION_TOKEN", token)
}

export function formatSecondsTimestamp(timestamp: number | null | undefined): string {
  if (!timestamp) {
    return ""
  }
  const date = new Date(timestamp * 1000);
  const pad = (num: number): string => num.toString().padStart(2, '0');
  const day = pad(date.getDate());
  const month = pad(date.getMonth() + 1);
  const year = date.getFullYear();
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

export function getErrorMessage(error: any) {
  return error instanceof RejectedHttpResponseError ? error.message : "Um erro ocorreu"
}

export function isUrlValid(url: string): boolean {
  try {
    new URL(url)
    return true
  } catch (e) {
    return false
  }
}

const ufIbgeCodes: {[key: string]: string} = {
  '12': 'ac',
  '27': 'al',
  '16': 'ap',
  '13': 'am',
  '29': 'ba',
  '23': 'ce',
  '53': 'df',
  '32': 'es',
  '52': 'go',
  '21': 'ma',
  '51': 'mt',
  '50': 'ms',
  '31': 'mg',
  '15': 'pa',
  '25': 'pb',
  '41': 'pr',
  '26': 'pe',
  '22': 'pi',
  '33': 'rj',
  '24': 'rn',
  '43': 'rs',
  '11': 'ro',
  '14': 'rr',
  '42': 'sc',
  '35': 'sp',
  '28': 'se',
  '17': 'to'
}

export const ufIbgeCodesConversor = (code: string): string => {
  return ufIbgeCodes[code] ? ufIbgeCodes[code].toUpperCase() : code
}

const modelCodes: {[key: string]: string} = {
  '55': 'DANFE',
  '59': 'SAT',
  '65': 'NFCE'
}

export const modelCodeConversor = (code: string): string => {
  return modelCodes[code] ? modelCodes[code].toUpperCase() : code
}

export const selectUfOptions = [
  { value: null, description: "Todas" },
  { value: "12", description: "Acre" },
  { value: "27", description: "Alagoas" },
  { value: "13", description: "Amazonas" },
  { value: "16", description: "Amapá"},
  { value: "29", description: "Bahia" },
  { value: "23", description: "Ceará" },
  { value: "53", description: "Distrito Federal" },
  { value: "32", description: "Espírito Santo" },
  { value: "52", description: "Goiás" },
  { value: "21", description: "Maranhão" },
  { value: "51", description: "Mato Grosso" },
  { value: "50", description: "Mato Grosso do Sul" },
  { value: "31", description: "Minas Gerais" },
  { value: "15", description: "Pará" },
  { value: "25", description: "Paraíba" },
  { value: "41", description: "Paraná"},
  { value: "26", description: "Pernambuco" },
  { value: "22", description: "Piauí" },
  { value: "33", description: "Rio de Janeiro" },
  { value: "24", description: "Rio Grande do Norte" },
  { value: "43", description: "Rio Grande do Sul"},
  { value: "11", description: "Rondônia" },
  { value: "14", description: "Roraima" },
  { value: "42", description: "Santa Catarina" },
  { value: "35", description: "São Paulo" },
  { value: "28", description: "Sergipe" },
  { value: "17", description: "Tocantins" }
]

export const selectModelOptions = [
  { value: null, description: "Todos" },
  { value: "65", description: "Nfce" },
  { value: "59", description: "Sat" },
  { value: "55", description: "Danfe" }
]

export const ufs = [
  { initials: "AC", name: "Acre", ibgeCode: "12" },
  { initials: "AL", name: "Alagoas", ibgeCode: "27" },
  { initials: "AP", name: "Amapá", ibgeCode: "16" },
  { initials: "AM", name: "Amazonas", ibgeCode: "13" },
  { initials: "BA", name: "Bahia", ibgeCode: "29" },
  { initials: "CE", name: "Ceará", ibgeCode: "23" },
  { initials: "DF", name: "Distrito Federal", ibgeCode: "53" },
  { initials: "ES", name: "Espírito Santo", ibgeCode: "32" },
  { initials: "GO", name: "Goiás", ibgeCode: "52" },
  { initials: "MA", name: "Maranhão", ibgeCode: "21" },
  { initials: "MT", name: "Mato Grosso", ibgeCode: "51" },
  { initials: "MS", name: "Mato Grosso do Sul", ibgeCode: "50" },
  { initials: "MG", name: "Minas Gerais", ibgeCode: "31" },
  { initials: "PA", name: "Pará", ibgeCode: "15" },
  { initials: "PB", name: "Paraíba", ibgeCode: "25" },
  { initials: "PR", name: "Paraná", ibgeCode: "41" },
  { initials: "PE", name: "Pernambuco", ibgeCode: "26" },
  { initials: "PI", name: "Piauí", ibgeCode: "22" },
  { initials: "RJ", name: "Rio de Janeiro", ibgeCode: "33" },
  { initials: "RN", name: "Rio Grande do Norte", ibgeCode: "24" },
  { initials: "RS", name: "Rio Grande do Sul", ibgeCode: "43" },
  { initials: "RO", name: "Rondônia", ibgeCode: "11" },
  { initials: "RR", name: "Roraima", ibgeCode: "14" },
  { initials: "SC", name: "Santa Catarina", ibgeCode: "42" },
  { initials: "SP", name: "São Paulo", ibgeCode: "35" },
  { initials: "SE", name: "Sergipe", ibgeCode: "28" },
  { initials: "TO", name: "Tocantins", ibgeCode: "17" }
];

export const models = [
  { code: "65", name: "NFCE" },
  { code: "59", name: "SAT" },
  { code: "55", name: "DANFE" }
]