import styled from "styled-components"
import Tabs from "../../ui/tabs"
import Column from "../../ui/column"
import useUrlState from "../../../hooks/use-url-state"
import LoginTab from "./login-tab"
import RecoverPasswordTab from "./recover-password-tab"

const Wrapper = styled.div`
  background-color: #757cc3;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  @media (max-width: 800px) {
    align-items: start;
    padding-top: 20px;
  }
`

const CenterBox = styled.div`
  width: 400px;
  max-width: 95vw;
  display: flex;
  gap: 20px;
  flex-direction: column;
`

const Title = styled.div`
  font-size: 26px;
  font-weight: bolder;
  color: white;
  user-select: none;
`

const TabContent = styled.div`
  height: 300px;
`

const LoginPage = () => {
  const [ tab, setTab ] = useUrlState("tab", "login")
  return (
    <Wrapper>
      <CenterBox>
        <Column>
          <Title>
            Argo
          </Title>
          <div style={{ display: "none" }}>
            <Tabs
              invertColors
              value={tab}
              onChange={tab => setTab(tab)}
              options={[
                { id: "login", name: "Login" },
                { id: "recover-password", name: "Recuperar Senha" }
              ]}
            />
          </div>
        </Column>
        <TabContent>
          {tab !== "login" ? null : <LoginTab />}
          {tab !== "recover-password" ? null : <RecoverPasswordTab />}
        </TabContent>
      </CenterBox>
    </Wrapper>
  )
}

export default LoginPage