import { useExtractorPerformanceReport } from "../../../hooks/report"
import { Chart } from "react-google-charts"
import useUrlState from "../../../hooks/use-url-state"
import SelectField from "../../ui/select-field"
import { getErrorMessage, selectModelOptions, selectUfOptions } from "../../../utils"
import styled from "styled-components"
import { useProjects } from "../../../hooks/projects"

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 20px
`

const Title = styled.h2`
  font-size: 20px;
  font-weight: 700;
  line-height: 121.83%;
  border-bottom: solid 1px black;
  padding: 15px 0;
  margin: 0 0 15px 0;
`

const ChartWrapper = styled.div`
  border: solid 1px black;
  border-radius: 5px;
  padding: 0 15px 15px 15px;
  box-sizing: border-box;
`

const SelectFieldWrapper = styled.div<{ flex?: number }>`
  flex: ${props => props.flex || 1};
`

function getRandomColor() {
  const randomColor = Math.floor(Math.random() * 0xFFFFFF);
  return `#${ randomColor.toString(16).padStart(6, '0') }`;
}

const fixedColors: Record<string, string> = {
  OK: "green",
  UNHANDLED_ERROR: "red",
  TOTAL: "blue",
  ACCESS_DENIED: "gray",
  NOT_FOUND_ACCESS_KEY: "purple",
  EXTRACTION_TIMEOUT: "brown"
}

const ExtractorPerformance = () => {

  const { data: projects } = useProjects()

  const [ projectId, setProjectId ] = useUrlState("projectId", null)
  const [ uf, setUf ] = useUrlState("uf", null)
  const [ model, setModel ] = useUrlState("model", null)

  const { data, error } = useExtractorPerformanceReport({
    projectId: projectId ? parseInt(projectId) : null, uf, model
  })

  const projectOptions = projects ? [
    {
      value: null,
      description: "Todos"
    },
    ...projects
      .map(p => ({ value: String(p.id), description: p.name }))
      .sort((a, b) => a.description.localeCompare(b.description))
  ] : [
    {
      value: null,
      description: "Carregando..."
    }
  ]

  let chartData: any[][] | undefined = undefined

  let seriesTitles: string[] = []

  if (data) {
    const indexedData: Record<string, number> = {}
    for (const item of data) {
      if (!seriesTitles.includes(item.result)) {
        seriesTitles.push(item.result)
      }
      indexedData[`${item.hour}:${item.result}`] = item.total
    }
    const xAxisLabels = getDates()
    seriesTitles = seriesTitles.sort((a, b) => {
      if (a === 'TOTAL') return -1
      if (b === 'TOTAL') return 1
      if (a === 'OK') return -1
      if (b === 'OK') return 1
      if (a === 'UNHANDLED_ERROR') return -1
      if (b === 'UNHANDLED_ERROR') return 1
      return a.localeCompare(b)
    })
    chartData = [[ "Período", ...seriesTitles.map(i => i.replaceAll("_", " ")) ]]
    for (const xAxisLabel of xAxisLabels) {
      const xAxisLabelFormatted = parseInt(xAxisLabel.replaceAll(/.*? (\d{2}):.*/g, "$1")) + "h"
      const current: any[] = [ xAxisLabelFormatted ]
      for (const seriesTitle of seriesTitles) {
        current.push(indexedData[`${xAxisLabel}:${seriesTitle}`] || 0)
      }
      chartData.push(current)
    }
  }

  return (
    <>
      <FormWrapper>
        <SelectFieldWrapper flex={4}>
          <SelectField
            label="Projeto"
            options={projectOptions}
            value={projectId ? String(projectId) : null}
            onChange={setProjectId}
          />
        </SelectFieldWrapper>
        <SelectFieldWrapper>
          <SelectField
            label="UF"
            options={selectUfOptions}
            onChange={setUf}
            value={uf}
          />
        </SelectFieldWrapper>
        <SelectFieldWrapper>
          <SelectField
            label="Modelo"
            options={selectModelOptions}
            onChange={setModel}
            value={model}
          />
        </SelectFieldWrapper>
      </FormWrapper>
      <ChartWrapper>
        <Title>
          Performance de Extratores
        </Title>
        {
          !data ? (
            error ? (
              getErrorMessage(error)
            ) : (
              <>
                Carregando...
              </>
            )
          ) : (
            !data.length ? (
              <div>
                Sem resultados
              </div>
            ) : (
              <Chart
                width="100%"
                height="500px"
                chartType="LineChart"
                options={{
                  curveType: "function",
                  chartArea: { width: '70%', height: '90%', left: '50' },
                  series: seriesTitles.map(s => (
                    {
                      color: fixedColors[s] || getRandomColor()
                    }
                  )),
                  hAxis: {
                    textStyle: {
                      fontName: "Poppins"
                    }
                  },
                  vAxis: {
                    textStyle: {
                      fontName: "Poppins"
                    },
                    viewWindow: {
                      min: 0, // Define o início do eixo Y em 0
                    },
                  }
                }}
                data={chartData}
              />
            )
          )
        }
      </ChartWrapper>
    </>
  )
}

function getDates() {

  function formatDate(date: Date) {
    const pad = (n: number) => n.toString().padStart(2, '0')
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ${pad(date.getHours())}:00:00`
  }

  let start = new Date()
  start.setDate(start.getDate() - 1)

  const end = new Date()

  const interval = 60 * 60 * 1000;

  const filledDates = []

  for (let current = start; current <= end; current = new Date(current.getTime() + interval)) {
    filledDates.push(formatDate(current))
  }

  return filledDates
}

export default ExtractorPerformance