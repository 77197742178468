import { useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

function useUrlState<T extends string | number | undefined | null = string | undefined>(paramName: string, defaultValue?: T): [
  T | string, (value: T | string) => void
] {
  const location = useLocation()
  const navigate = useNavigate()

  const setValue = useCallback((newValue: T | string) => {
    const newParams = new URLSearchParams(window.location.search)
    if (newParams.get(paramName) === newValue) {
      return
    }
    if (newValue) {
      newParams.set(paramName, String(newValue))
    } else {
      newParams.delete(paramName)
    }
    navigate(`${window.location.pathname}?${newParams}`)
  }, [ navigate, paramName ])

  const searchParams = new URLSearchParams(location.search);
  const paramValue = searchParams.get(paramName);

  let value: T | string = (paramValue ?? defaultValue) as T | string

  if (typeof defaultValue === 'number') {
    value = paramValue ? (Number(paramValue) as T) : defaultValue
  }

  return [ value as T | string, setValue ]
}

export default useUrlState
