import useRequest from "./use-request"
import { useQuery } from "react-query"

export type ReceiptQueueItem = {
  projectId: number;
  projectName: string;
  accessKey: string;
  url: string;
  uf: string;
  model: string;
  status: string;
  notifying: boolean;
  receivedAt: string;
  retryAt: string;
  processedAt: string;
  identificationRate: number;
  webhookAttemptAmount: number;
  captchaBrokenAmount: number;
  extractionAttemptAmount: number;
  lastExtractionResult: string;
}

export const RECEIPT_QUEUE_KEY = "RECEIPT_QUEUE"

export const useReceiptQueue = (
  params: {
    uf?: string | null,
    model?: string | null,
    projectId?: number | null,
    accessKey?: string | null
    status?: string | null
    currentPage?: string | null
    formattedStartDate?: string | null
    formattedEndDate?: string | null
  }
) => {

  const { uf, model, projectId, accessKey, status, currentPage, formattedStartDate, formattedEndDate } = params
  const request = useRequest()

  return useQuery([RECEIPT_QUEUE_KEY, uf, model, projectId, accessKey, status, currentPage, formattedStartDate, formattedEndDate], async () => {
    const response = await request<{ result: ReceiptQueueItem[]; total: number; }>({
      url: "/v1/receipt/queue", params: { uf, model, projectId, accessKey, status, currentPage, formattedStartDate, formattedEndDate }
    })
    return response.body
  })

}

export type ReceiptAttemptItem = {
  projectId: number;
  projectName: string;
  accessKey: string;
  result: string;
  workerName: string;
  durationMs: number;
  attemptDt: string;
  id: number;
  hasJson: boolean;
  hasHtml: boolean;
  hasStacktrace: boolean;
  hasScreenshot: boolean;
}

export const RECEIPT_ATTEMPT = "RECEIPT_ATTEMPT_KEY"

export const useReceiptAttempts = (
  params: {
    uf?: string | null,
    model?: string | null,
    projectId?: number | null,
    accessKey?: string | null
    result?: string | null
    currentPage?: string | null
    startDate?: string | null
    endDate?: string | null
    hasJson?: boolean
    hasHtml?: boolean
    hasStacktrace?: boolean
    hasScreenshot?: boolean
  }
) => {

  const { uf, model, projectId, accessKey, result, currentPage, startDate, endDate, hasJson, hasHtml, hasStacktrace, hasScreenshot } = params

  const request = useRequest()

  return useQuery([RECEIPT_ATTEMPT, uf, model, projectId, accessKey, result, currentPage, startDate, endDate, hasJson, hasHtml, hasStacktrace, hasScreenshot], async () => {
    const response = await request<{ result: ReceiptAttemptItem[]; total: number; }>({
      url: "/v1/receipt/attempt", params: { uf, model, projectId, accessKey, result, currentPage, startDate, endDate, hasJson, hasHtml, hasStacktrace, hasScreenshot }
    })
    return response.body
  })
}

export type ReceiptAttemptItemData = {
  projectId: number;
  json: string;
  html: string;
  stacktrace: string;
  screenshotBase64: string;
}

export const useReceiptAttemptItemData = (
  params: {
    id: number | null
  }
) => {
  const { id } = params

  const request = useRequest()

  return useQuery([id], async () => {
    const [jsonResponse, htmlResponse, stacktraceResponse, screenshotResponse] = await Promise.all([
      request<{ result: ReceiptAttemptItemData[] }>({
        url: `/v1/receipt/attempt/${id}/json`
      }),
      request<{
        html: any; result: ReceiptAttemptItemData[]
      }>({
        url: `/v1/receipt/attempt/${id}/html`
      }),
      request<{
        stacktrace: any; result: ReceiptAttemptItemData[]
      }>({
        url: `/v1/receipt/attempt/${id}/stacktrace`
      }),
      request<{
        screenshotBase64: any; result: ReceiptAttemptItemData[]
      }>({
        url: `/v1/receipt/attempt/${id}/screenshot`
      })
    ])
    return {
      json: jsonResponse.body,
      html: htmlResponse.body.html,
      stacktrace: stacktraceResponse.body.stacktrace,
      screenshotBase64: screenshotResponse.body.screenshotBase64
    }
  })
}